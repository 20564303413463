<template>
  <div class="home">
    <div class="header">
      <el-carousel indicator-position="none" :interval="60000">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <el-image
            style="width: 100vw; height: 440rpx"
            :src="item.path"
            fit="fill"
          ></el-image>
          <div class="bannerText">
            <div class="bannerTitle">{{ item.title }}</div>
            <div class="bannerContent">{{ item.content }}</div>
            <div class="bannerButton">
              <el-button @click="identity" class="hvr-wobble-vertical"
                >申请入驻<i class="el-icon-right el-icon--right"></i
              ></el-button>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content">
      <div class="navList">
        <div class="navItem">
          <el-image
            style="width: 45px; height: 45px"
            :src="require('@/assets/image/nav-icon-1.png')"
            fit="contain"
          ></el-image>
          <div class="navItemTitle">合规发行</div>
          <div class="navItemContent">
            所有作品均可申请ISBN版号，合规数字出版，每一份数字版权资产都是正规的数字出版物。
          </div>
        </div>
        <div class="navItem">
          <el-image
            style="width: 45px; height: 45px"
            :src="require('@/assets/image/nav-icon-2.png')"
            fit="contain"
          ></el-image>
          <div class="navItemTitle">安全高效</div>
          <div class="navItemContent">
            斑马中国提供专业高效的版权确权服务，支持开放api接入，供企业快速确权，保护版权权益，规避版权纠纷。
          </div>
        </div>
        <div class="navItem">
          <el-image
            style="width: 45px; height: 45px"
            :src="require('@/assets/image/nav-icon-3.png')"
            fit="contain"
          ></el-image>
          <div class="navItemTitle">多元服务</div>
          <div class="navItemContent">
            提供DAO社群管理，直播、IM通信、线上活动、投票等服务。助力企业运营用户社群。
          </div>
        </div>
        <div class="navItem">
          <el-image
            style="width: 45px; height: 45px"
            :src="require('@/assets/image/nav-icon-4.png')"
            fit="contain"
          ></el-image>
          <div class="navItemTitle">共建共享</div>
          <div class="navItemContent">
            斑马中国依托中国数字版权产业联盟、文创链等，正加速构建新一代web3.0价值体系，已邀请50余加企业加盟。
          </div>
        </div>
      </div>
      <div class="module tabs">
        <div class="title">企业服务一览</div>
        <el-tabs type="border-card" stretch>
          <el-tab-pane label="数字出版发行">
            <div class="float animate__animated animate__fadeIn">
              <div class="left">
                <div class="text">
                  <p class="title2">数字出版物线上发行</p>
                  <p class="textItem">
                    为寻求文旅、文艺、文娱、文创等领域结合数字版权发展路径，斑马中国率先探索基于区块链技术、行政、司法的数字版权合规之路。并以此为切入点，致力于构建数字版权线上、线下实体经济赋能的基于web3.0的未来道场景建设。
                  </p>
                  <p class="title2">业务优势</p>
                  <p class="textItem">
                    合法合规流资质于国内领导水平，为企业发行的作品保驾护航，规避风险。数字版权资产支持线上交易流通，交易费率低
                  </p>
                  <p class="textItem"></p>
                </div>
              </div>
              <div class="right">
                <el-image
                  :src="require('@/assets/image/service-icon-1.png')"
                  fit="contain"
                ></el-image>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="版权确权">
            <div class="float animate__animated animate__fadeIn">
              <div class="left">
                <div class="text">
                  <p class="title2">数字出版物线上发行</p>
                  <p class="textItem">
                    为寻求文旅、文艺、文娱、文创等领域结合数字版权发展路径，斑马中国率先探索基于区块链技术、行政、司法的数字版权合规之路。并以此为切入点，致力于构建数字版权线上、线下实体经济赋能的基于web3.0的未来道场景建设。
                  </p>
                  <p class="title2">业务优势</p>
                  <p class="textItem">
                    合法合规流资质于国内领导水平，为企业发行的作品保驾护航，规避风险。数字版权资产支持线上交易流通，交易费率低
                  </p>
                  <p class="textItem"></p>
                </div>
              </div>
              <div class="right">
                <el-image
                  :src="require('@/assets/image/service-icon-2.png')"
                  fit="contain"
                ></el-image>
              </div></div
          ></el-tab-pane>
          <el-tab-pane label="IP合作">
            <div class="float animate__animated animate__fadeIn">
              <div class="left">
                <div class="text">
                  <p class="title2">数字出版物线上发行</p>
                  <p class="textItem">
                    为寻求文旅、文艺、文娱、文创等领域结合数字版权发展路径，斑马中国率先探索基于区块链技术、行政、司法的数字版权合规之路。并以此为切入点，致力于构建数字版权线上、线下实体经济赋能的基于web3.0的未来道场景建设。
                  </p>
                  <p class="title2">业务优势</p>
                  <p class="textItem">
                    合法合规流资质于国内领导水平，为企业发行的作品保驾护航，规避风险。数字版权资产支持线上交易流通，交易费率低
                  </p>
                  <p class="textItem"></p>
                </div>
              </div>
              <div class="right">
                <el-image
                  :src="require('@/assets/image/service-icon-3.png')"
                  fit="contain"
                ></el-image>
              </div></div
          ></el-tab-pane>
          <el-tab-pane label="内容审核">
            <div class="float animate__animated animate__fadeIn">
              <div class="left">
                <div class="text">
                  <p class="title2">数字出版物线上发行</p>
                  <p class="textItem">
                    为寻求文旅、文艺、文娱、文创等领域结合数字版权发展路径，斑马中国率先探索基于区块链技术、行政、司法的数字版权合规之路。并以此为切入点，致力于构建数字版权线上、线下实体经济赋能的基于web3.0的未来道场景建设。
                  </p>
                  <p class="title2">业务优势</p>
                  <p class="textItem">
                    合法合规流资质于国内领导水平，为企业发行的作品保驾护航，规避风险。数字版权资产支持线上交易流通，交易费率低
                  </p>
                  <p class="textItem"></p>
                </div>
              </div>
              <div class="right">
                <el-image
                  :src="require('@/assets/image/service-icon-4.png')"
                  fit="contain"
                ></el-image>
              </div></div
          ></el-tab-pane>
        </el-tabs>
      </div>
      <!-- 发展历程 -->
      <div class="module" id="rate">
        <div class="title">服务费率</div>
        <div class="rate">
          <div class="rateItem">
            <div class="rateTitle">
              <el-avatar
                size="large"
                :src="require('@/assets/image/rate-icon-1.png')"
              ></el-avatar>
              <div>数字出版发行费率</div>
            </div>
            <div class="rateList">
              <div class="rateListItem">
                <div class="rateEleTitle">
                  <span>自主发行</span>
                  <span></span>
                </div>
                <div class="rateEleContent">
                  <span>1</span>
                  <span>%</span>
                </div>
              </div>
              <div class="rateListItem">
                <div class="rateEleTitle">
                  <span>版权IP授权联合发行</span>
                  <span></span>
                </div>
                <div class="rateEleContent">
                  <span>可协商</span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div class="rateItem">
            <div class="rateTitle">
              <el-avatar
                size="large"
                :src="require('@/assets/image/rate-icon-2.png')"
              ></el-avatar>
              <div>版权确权</div>
            </div>
            <div class="rateList">
              <div class="rateListItem">
                <div class="rateEleTitle">
                  <span>版权存证</span>
                  <span>最低</span>
                </div>
                <div class="rateEleContent">
                  <span>-100</span>
                  <span>%</span>
                </div>
              </div>
              <div class="rateListItem">
                <div class="rateEleTitle">
                  <span>版权登记</span>
                  <span>最低</span>
                </div>
                <div class="rateEleContent">
                  <span>-100</span>
                  <span>%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="rateItem">
            <div class="rateTitle">
              <el-avatar
                size="large"
                :src="require('@/assets/image/rate-icon-3.png')"
              ></el-avatar>
              <div>内容审核</div>
            </div>
            <div class="rateList">
              <div class="rateListItem">
                <div class="rateEleTitle">
                  <span>文字审核</span>
                  <span>最低</span>
                </div>
                <div class="rateEleContent">
                  <span>-100</span>
                  <span>%</span>
                </div>
              </div>
              <div class="rateListItem">
                <div class="rateEleTitle">
                  <span>图片/视频审核</span>
                  <span>最低</span>
                </div>
                <div class="rateEleContent">
                  <span>-100</span>
                  <span>%</span>
                </div>
              </div>
              <div class="rateListItem">
                <div class="rateEleTitle">
                  <span>音频审核</span>
                  <span>最低</span>
                </div>
                <div class="rateEleContent">
                  <span>-100</span>
                  <span>%</span>
                </div>
              </div>
            </div>
          </div>

          <div class="rateItem">
            <div class="rateTitle">
              <el-avatar
                size="large"
                :src="require('@/assets/image/rate-icon-4.png')"
              ></el-avatar>
              <div>交易费率</div>
            </div>
            <div class="rateList">
              <div class="rateListItem">
                <div class="rateEleTitle">
                  <span>版权交易市场费率</span>
                  <span>最低</span>
                </div>
                <div class="rateEleContent">
                  <span>-75</span>
                  <span>%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="module">
        <div class="title">合作案例</div>
        <el-image
          class="hvr-grow"
          style="width: 868px; height: 586px; margin: 40px auto 0"
          :src="require('@/assets/image/cooperation-case.png')"
        ></el-image>
      </div>
      <!-- 合作伙伴 -->
      <div class="module">
        <div class="title">合作伙伴</div>
        <ul class="list">
          <li
            class="hvr-grow"
            v-for="(item, index) in partnerList"
            :key="index"
          >
            <el-image
              :src="item.iconPath"
              fit="contain"
              :style="{ transform: 'scale(' + item.scale + ')' }"
            ></el-image>
          </li>
        </ul>
      </div>
    </div>
    <div class="footBtn">
      <div class="title">点击按钮一键申请入驻</div>
      <el-button @click="identity" class="hvr-wobble-vertical"
        >申请入驻<i class="el-icon-right el-icon--right"></i
      ></el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bannerList: [
        {
          title: "数字出版 当选斑马中国",
          content: "合规 安全 专业 高效",
          path: require("@/assets/image/banner-bg.png"),
        },
      ],
      partnerList: [
        {
          iconPath: require("@/assets/image/logo-1.png"),
          scale: "1.2",
        },
        {
          iconPath: require("@/assets/image/logo-2.png"),
          scale: "1.2",
        },
        {
          iconPath: require("@/assets/image/logo-3.png"),
          scale: "1.2",
        },
        {
          iconPath: require("@/assets/image/logo-4.png"),
          scale: "1.2",
        },
        {
          iconPath: require("@/assets/image/logo-5.png"),
          scale: "1.2",
        },
        {
          iconPath: require("@/assets/image/logo-6.png"),
          scale: "1.2",
        },
        {
          iconPath: require("@/assets/image/logo-7.png"),
          scale: "1.2",
        },
        {
          iconPath: require("@/assets/image/logo-8.png"),
          scale: "1.2",
        },
        {
          iconPath: require("@/assets/image/logo-9.png"),
          scale: "1.2",
        },
        {
          iconPath: require("@/assets/image/logo-10.png"),
          scale: "1.2",
        },
        {
          iconPath: require("@/assets/image/logo-11.png"),
          scale: "1.2",
        },
        {
          iconPath: require("@/assets/image/logo-12.png"),
          scale: "1.2",
        },
        {
          iconPath: require("@/assets/image/logo-13.png"),
          scale: "1.2",
        },
        {
          iconPath: require("@/assets/image/logo-14.png"),
          scale: "1.2",
        },
        {
          iconPath: require("@/assets/image/logo-15.png"),
          scale: "1.2",
        },
      ],
    };
  },
 
  mounted() {},
  methods: {
    identity() {
      this.$router.push("/identity");
    },
  },
};
</script>
<style lang="scss" scoped>
.el-carousel__button {
  width: 60px !important;
  height: 6px !important;
}
.el-carousel__indicators--outside button {
  background: #fff !important;
}
.el-carousel__indicators--outside .el-carousel__indicator:hover button {
  background: #0a1028 !important;
  opacity: 1;
}
.el-backtop,
.el-calendar-table td.is-today {
  color: #0a1028;
}
.el-carousel__arrow--left,
.el-carousel__arrow--right {
  display: none !important;
}

div.home {
  width: 100vw;
  min-height: 100vh;
  font-family: "HarmonyOsSansR";
  div.header {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    ::v-deep .el-carousel__container {
      height: 440px;
    }
    .el-image {
      height: 440px;
    }
    div.bannerText {
      position: absolute;
      left: 16vw;
      top: 10vh;
      color: #fff;
      z-index: 9;
      text-align: left;
      font-family: "HarmonyOsSansR";
      div.bannerTitle {
        font-size: 44px;
        line-height: 61px;
      }
      div.bannerContent {
        color: #ffb216;
        font-size: 22px;
        line-height: 30px;
        text-align: left;
        letter-spacing: 0.15px;
        margin-top: 10px;
        font-weight: 300;
      }
      div.bannerButton {
        .el-button {
          margin-top: 36px;
          padding: 0;
          background-color: transparent;
          border: 2px solid #ffb216;
          color: #ffb216;
          font-size: 18px;
          line-height: 46px;
          width: 250px;
          position: relative;
          cursor: pointer;
          border-radius: 4px;
        }
      }
    }
    ul.el-carousel__indicators {
      position: absolute;
      right: 0;
      bottom: 100px;
    }
  }
  div.content {
    width: 100vw;
    background: #f2f6fa;
    div.navList {
      width: 100vw;
      display: flex;
      align-items: center;
      background-color: #091e50;
      padding: 20px 0;
      div.navItem {
        width: 25%;
        text-align: center;
        padding: 0 80px;
        div.navItemTitle {
          color: #ffb216;
          font-size: 20px;
          padding: 10px 0;
          font-weight: bold;
        }
        div.navItemContent {
          color: #fff5e4;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
    ::v-deep .el-tabs__header {
      background-color: transparent;
      border-bottom: none;
      .el-tabs__nav {
        height: 80px;
      }
      .el-tabs__item {
        height: 80px;
        line-height: 80px;
        font-size: 18px;
        color: #606266;
        border: none;
        border-top: 4px solid #fff;
      }
      .el-tabs__item.is-active {
        color: #ffb21f;
        font-weight: bold;
        // line-height: 76px;
        // height: 76px;
        border-top: 4px solid #ffb21f;
        box-sizing: border-box;
      }
    }
    div.title {
      text-align: center;
      font-size: 30px;
      font-family: "HarmonyOsSansM";
      color: #2a2e2e;
      padding: 20px 0;
      font-style: italic;
      margin-bottom: 30px;
    }
    div.title-lf {
      text-align: left;
      font-size: 30px;
      font-family: "HarmonyOsSansR";
      color: #2a2e2e;
      padding: 20px 0;
      font-style: italic;
      margin-bottom: 30px;
      span {
        margin-left: 220px;
        font-size: 14px;
        line-height: 25px;
        color: #5f6464;
        font-style: normal;
      }
    }
    div.module#rate {
      display: block;
    }
    div.module {
      padding: 40px 22vw 80px;
      background: #f2f6fa;
      display: flex;
      flex-direction: column;
      align-items: center;
      .el-tabs--border-card > .el-tabs__header {
        background-color: #fff;
        border-bottom: 1px solid #fff;
      }
      div.el-tabs--border-card {
        border: 0;
      }
      div.is-active {
        border-top: 4px solid #0a1028 !important;
        color: #303133;
      }
      .el-tabs__item:hover {
        color: #303133;
      }
      div.el-tabs__item {
        border-top: 4px solid #e1e3ea;
        border-right: 0;
        border-left: 0;
        font-size: 18px;
      }
      .el-tabs__content {
        padding: 40px;
      }
      div.text {
        text-align: left;
        color: #606266;
        font-weight: 500;
        font-size: 18px;
        p.textItem {
          line-height: 32px;
        }
        p.textItem.indent {
          text-indent: 40px;
        }
      }
      div.el-tabs {
        div.float {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px;
          div.left {
            padding-right: 40px;
          }
          div.right {
            width: 524px;
            height: 298px;
            .el-image {
              width: 524px;
              height: 298px;
            }
          }
          div.text {
            text-align: left;
            color: #606266;
            font-weight: 500;
            p.title2 {
              font-size: 24px;
              font-family: "HarmonyOsSansM";
              color: #303133;
              margin-bottom: 10px;
            }
            p.textItem {
              color: #606266;
              font-size: 15px;
              line-height: 25px;
              padding: 2px 0 16px;
              font-style: italic;
            }
          }
        }
      }
      div.culture {
        div.float {
          display: flex;
          align-items: center;
          justify-content: space-between;
          div.right {
            width: 50%;
            height: 50%;
          }
          div.text {
            text-align: left;
            color: #606266;
            font-weight: 500;
            font-style: 16px;
            p.title2 {
              font-size: 24px;
              font-family: "HarmonyOsSansM";
              color: #2a2e2e;
              font-style: italic;
              padding: 2px 0;
            }
            p.textItem {
              line-height: 32px;
              padding: 2px 0 16px;
            }
          }
        }
      }

      ul.list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li {
          width: 25%;
          margin: 10px 0;
          & > p {
            padding: 14px 0;
            text-align: center;
          }
        }
      }
      div.rate {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        .el-avatar {
          background: transparent;
        }
        div.rateItem:nth-of-type(1) {
          div.rateTitle > div {
            color: #0455ec;
          }
          div.rateEleContent {
            color: #0455ec;
          }
        }
        div.rateItem:nth-of-type(2) {
          div.rateTitle > div {
            color: #11cba3;
          }
          div.rateEleContent {
            color: #11cba3;
          }
        }
        div.rateItem:nth-of-type(3) {
          div.rateTitle > div {
            color: #ffb216;
          }
          div.rateEleContent {
            color: #ffb216;
          }
        }
        div.rateItem:nth-of-type(4) {
          div.rateTitle > div {
            color: #5626d6;
          }
          div.rateEleContent {
            color: #5626d6;
          }
        }
        div.rateItem:hover {
          transform: scale(1.05);
        }
        div.rateItem {
          width: 20%;
          background: #fff;
          box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.09);
          padding: 30px 0 30px 30px;
          border-radius: 10px;
          font-family: "微软雅黑";
          cursor: pointer;
          transition: all 0.5s; /* 所有的属性变化在0.5s的时间段内完成 */
          div.rateTitle {
            display: flex;
            align-items: center;
            font-size: 18px;
            & > div {
              margin-left: 10px;
              font-weight: bold;
            }
          }

          div.rateList {
            padding: 20px 0 0 2px;
            div.rateEleTitle {
              span:first-of-type {
                display: inline-block;
                font-size: 16px;
                font-weight: bold;
                color: #303133;
              }
              span:last-of-type {
                display: inline-block;
                font-size: 12px;
                color: #606266;
                margin-left: 6px;
              }
            }
            div.rateEleContent {
              padding: 14px 0 25px;
              font-weight: bold;
              span:first-of-type {
                font-size: 28px;
              }
              span:last-of-type {
                font-size: 20px;
                margin-left: 4px;
              }
            }
          }
        }
      }
      div.imgText {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-image {
          width: 50%;
        }
        div.text {
          color: #606266;
          font-size: 20px;
          width: 50%;
          padding: 0 20px;
          text-align: left;
          line-height: 30px;
          text-indent: 40px;
        }
      }
      div.workEnv {
        display: flex;
        align-items: center;
        height: auto;
        div.left {
          img {
            width: 300px;
            height: auto;
            transform: scale(1);
            transition: all 0.5s;
            cursor: pointer;
          }
        }
        div.left:hover img {
          transform: scale(1.05);
        }
        div.right {
          padding-left: 40px;
        }
        div.text {
          text-align: left;
          color: #606266;
          font-weight: 500;
          font-style: 16px;
          p.title2 {
            font-size: 44px;
            font-family: "HarmonyOsSansM";
            color: #333333;
            padding: 10px 0;
          }
          p.textItem {
            line-height: 26px;
            padding: 2px 0 10px;
          }
        }
      }
    }
    div.module:nth-child(even) {
      background: #fff;
    }
  }
  div.footBtn {
    height: 270px;
    background: url("../../assets/image/footer-bg.png");
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .title {
      font-size: 28px;
      font-weight: bold;
      color: #fff;
      padding: 0 0 30px;
    }
    .el-button {
      padding: 0;
      background-color: transparent;
      border: 2px solid #ffb216;
      color: #ffb216;
      font-size: 18px;
      line-height: 46px;
      width: 250px;
      position: relative;
      cursor: pointer;
      border-radius: 4px;
    }
  }
}
</style>
